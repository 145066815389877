import { toJS, computed, observable } from "mobx"
import RootStore from "./RootStore"
import * as R from "ramda"
import { attribute } from "./Attribute"
import { at } from "lodash"

/**
 * list of possible status for a project
 */
export enum status {
  candidate,
  scheduled,
  in_flight,
  suspended,
  completed,
  excluded,
}

// export var status = {
//   candidate: "candidate",
//   scheduled: "scheduled",
//   in_flight: "in_flight",
//   suspended: "suspended",
//   completed: "completed",
//   excluded: "excluded",
// }

export enum Mandatory {
  nonMandatory = "nonMandatory",
  Mandatory = "Mandatory",
  Excluded = "Excluded",
}

/**
 * containts possible types a prerequisites can be
 */
export enum Prerequisites {
  before = "before", // must start on or before this project
  completed_before = "completed_before", // must be fully completed before this project can start
  any = "any", //the preReq can be done at any time
}

export interface ProjectProps {
  initiativeId: number
  strategyId: number
  typeId: number
  scenarioId: number
  alive: boolean
  inFlight: boolean
  status: status
  // per-period, this is absolute
  benefits: attribute
  capabilities: Array<{
    id: string
    contribution: number
  }>
  strategicIndex: number
  code: string
  duration: number
  edited: boolean
  id: number | undefined
  mandatory: Mandatory
  name: string
  prerequisites: Array<{ name: string; type: Prerequisites }>
  attributes: Array<attribute>
  PhaseId: any
  startPeriod: number
  startPeriods: number[]
  fromDB: boolean
}

export class Project {
  @observable public initiativeId: number = 0
  @observable public strategyId: number = 0
  @observable public typeId: number = 0
  @observable public scenarioId: number = 0
  @observable public alive: boolean = false
  @observable public inFlight: boolean = false
  @observable public status: status = status.candidate
  // per-period, this is absolute
  @observable public benefits: attribute
  @observable public capabilities: Array<{
    id: string
    contribution: number
  }> = []
  @observable public strategicIndex: number = 0
  @observable public code: string = ""
  @observable public duration: number = 1
  // @computed public get duration(): number {
  //   console.log(this._duration)
  //   return this._duration
  // }
  /**
   * I HATE THIS but I have spent a day trying to get the setter and getter working and still cant, its bad, I know, good luck
   */
  public setDuration = (v: number) => {
    // a duration of 0 is not a valid project and brakes stuff
    if (v > 0) {
      this.duration = v
      // this.updateAttributesLength(v)
    }
  }

  @observable public edited: boolean = false
  @observable public id: number | undefined = undefined
  @observable public mandatory: Mandatory = Mandatory.nonMandatory
  @observable public name: string = ""
  @observable public prerequisites: Array<{
    name: string
    type: Prerequisites
  }> = []
  @observable public attributes: Array<attribute>
  @observable public PhaseId: any
  @observable public startPeriod: number = 0
  @observable public startPeriods: number[] = []
  @observable public fromDB: boolean = false

  // /**
  //  * returns the benefit at the current start period
  //  * this currently doen't work -- doesnt seem to update, use getBenefit for the time being
  //  */
  // @computed public get benefit(): number {
  //   return this.benefits.values[this.startPeriod] == undefined ? 0 : this.benefits.values[this.startPeriod]
  // }


  public constructor(props: ProjectProps) {
    this.initiativeId = props.initiativeId
    this.strategyId = props.strategyId
    this.typeId = props.typeId
    this.scenarioId = props.scenarioId
    this.alive = props.alive
    this.status = props.status
    // per-period, this is absolute
    this.benefits = props.benefits
    this.capabilities = R.clone(props.capabilities)
    this.strategicIndex = props.strategicIndex
    this.code = props.code
    this.duration = props.duration
    this.edited = props.edited
    this.id = props.id
    this.mandatory = props.mandatory
    this.name = props.name
    this.prerequisites = R.clone(props.prerequisites)
    this.attributes = props.attributes
    this.PhaseId = props.PhaseId
    // index for start period, starting at 0
    this.startPeriod = props.startPeriod
    this.startPeriods = R.clone(props.startPeriods)
    this.fromDB = props.fromDB
  }

  /**
   * sums up the total of an attribute across all periods of a project
   *
   * @param project the project being summed
   * @param target the attribute which the you want totaled
   * @returns the total of the attribute or 0 if it cant be found
   */
  public attributeTotal = (target: string) => {
    let size = 0
    let attr = this.attributes.findIndex(
      (attribute) => target === attribute.name
    )
    if (attr != -1) {
      size = this.attributes[attr].total()
    }
    return size
  }

  /**
   * updates the attributes data to match the length of duration
   *
   * @param fill optional what to fill new periods with
   */
  public updateAttributesLength = (length: number, fill = 0) => {
    if (this.attributes !== undefined) {
      for(let i = 0; i < this.attributes.length; i++){
      // this.attributes.forEach((attribute) => {
        console.log("Update Length : "+ length)
        let difference = length - this.attributes[i].values.length
        let newValues = this.attributes[i].values
        if (difference > 0) {
            // grow
            for (let i = 0; i < difference; i++) {
                newValues.push(fill)
            }
        } else if (difference < 0) {
            //shrink
            newValues= this.attributes[i].values.slice(0, length)
        }
        this.attributes[i].values = newValues
      // })
      }
    }
    console.log(this.attributes)
  }



  /**
   * returns the value for a selected attribute and periods or undefined
   * @param name the name of the desired attr
   * @param period the relative period number to get the value for ( 0 = 1)
   */
  public getAttribute = (name: string, period: number) => {
    let attr = this.attributes.find((attr) => attr.name === name)
    if (attr) {
      if (attr.values.length > period) {
        return attr.values[period]
      } else {
        // console.warn("getAttribute out of bounds request")
      }
    }
    return undefined
  }

  /**
 * returns the benefit at the current start period
 */
  public getBenefit = () => {
    if (this.startPeriod === null || this.startPeriod === undefined) {
      return this.getPeriodBenefit(0);
    } else {
      return this.getPeriodBenefit(this.startPeriod)
    }
  }
  /**
   * returns the benefit for a given period or undefined if not found
   * periods are 0 indexed
   *
   * @param period the period you want the benefit for
   * @returns the benefit, or 0 if null/ undefined 
   */
  public getPeriodBenefit = (period: number) => {
    let benefit = 0
    // if (period < this.benefits.values.length) {
    //   benefit = this.benefits.values[period]
    // }
    if (this.benefits.simplified) {
      benefit = this.benefits.values[0];
      // console.log("simplified")
      // console.log(this.benefits)
    } else {
      if (period < this.benefits.values.length) {
        benefit = this.benefits.values[period]
      }
    }

    return benefit
  }


}

export default Project
