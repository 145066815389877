import * as React from 'react'
import * as localforage from 'localforage'
import { observer, inject } from 'mobx-react'
import {
  handleAuthentication,
  AuthType,
  getUserFromAuthResult
} from '../auth/auth'
import PermissionStore from '../mobx/PermissionStore'
import { Redirect } from 'react-router-dom'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'

interface Props {
  Auth: AuthType
  isTokenReceived: boolean
  tokenParsed(): void
}

interface InjectedProps extends Props {
  permissionStore: PermissionStore;
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore
  };
})
@observer
export class LoginCallBack extends React.Component<Props, {}> {
  private get injected() {
    return this.props as InjectedProps;
  }
  constructor(props: Props) {
    super(props)
    this.handleAuthentication(props)
  }

  private handleAuthentication = ({ location }: any) => {
    if (!this.props.isTokenReceived) {
      if (/access_token|id_token|error/.test(location.hash)) {
        handleAuthentication(this.props.Auth, this.setupToken)
      }
    }
  }

  public componentDidUpdate() {
    console.log("LoginCallBack Update")
    this.handleAuthentication(this.props)
  }

  private setupToken = (authResult: any) => {
    this.props.tokenParsed()
    const { permissionStore } = this.injected;

    const user = getUserFromAuthResult(authResult)
    localforage.setItem('user', user)
    

    permissionStore.setUser(user)
    console.log(user)
    // permissionStore.setPermissions(user.profile.permissions)
    permissionStore.setLoggedIn(true)
  }

  public render(): JSX.Element {
    const { permissionStore } = this.injected
    if (!permissionStore.loggedIn) {
      return (
        <div>
          <p>There was an error processing your request, please go back to the homepage</p>
        </div>
      )
    }
    return <Redirect to="/dashboard" />
  }
}

export default LoginCallBack
