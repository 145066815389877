import Project, { ProjectProps, status, Mandatory } from "../mobx/Project"
import { stat } from "fs"
import { toNumber, update } from "lodash"
import { ifElse, project } from "ramda"

/***
 *returns the list of possible project states for that project
 *this list includes manual and non-manual changes
 */
export const nextPossibleProjectState = (project: ProjectProps, manualOnly: boolean = true) => {
  let newStatus: status[] = []
  const current = project.status

  switch (current) {
    case status.candidate:
      newStatus = [status.excluded]
      if (manualOnly === false) {
        newStatus.push(status.scheduled)
      }
      break

    case status.scheduled:
      newStatus = [status.in_flight, status.excluded, status.candidate]
      break

    case status.in_flight:
      newStatus = [status.completed, status.suspended, status.excluded]
      break

    case status.suspended:
      newStatus = [status.excluded]
      if (manualOnly === false) {
        newStatus.push(status.in_flight)
      }
      break

    case status.completed:
      newStatus = []
      break

    case status.excluded:
      if (project.inFlight === true) {
        newStatus = [status.suspended]
      } else {
        newStatus = [status.candidate]
      }
      break

    default:
      break
  }

  return newStatus
}

/**
 * updates a projects state and updates its alive, and mandatory and inflight status
 * @param project 
 * @param newStatus 
 */
export const updateStatus = (project: Project, newStatus: status) => {
  project.status = newStatus

  switch (newStatus) {
    
    case status.candidate: //!!NOTE -- falls through to suspended !!
      project.alive = false

    case status.suspended: //!!
      project.alive = false
      // make sure its not excluded
      if (project.mandatory == Mandatory.Excluded) {
        project.mandatory = Mandatory.nonMandatory
      }
      break

    case status.in_flight:
      project.inFlight = true
      project.alive = true
      // make sure its not excluded
      if (project.mandatory == Mandatory.Excluded) {
        project.mandatory = Mandatory.nonMandatory
      }
      break

    case status.completed:
      project.mandatory = Mandatory.Mandatory
      break

    case status.scheduled:
      project.alive = true
            // make sure its not excluded
            if (project.mandatory == Mandatory.Excluded) {
              project.mandatory = Mandatory.nonMandatory
            }
      break

    case status.excluded:
      project.alive = false
      project.mandatory = Mandatory.Excluded
      break

    default:
      break
  }
}

/**
 * calculates the status of the project based on its attributes
 * default case is candidate, completed wont be calculated
 * @param project the project to re-status
 * @param currentPeriod the current period index, 1 indexed not 0
 */
export const calculateStatus = (project: Project, currentPeriod: number) => {
  //if a project has being set to completed, ignore it
  if (project.status == status.completed) {
    return
  }
  //is excluded
  if (project.mandatory === Mandatory.Excluded) {
    project.status = status.excluded
    if (project.alive === true) {
      throw new Error("Project Alive and Excluded");
    }
    return
  }
  else if (project.alive === true) { // check if alive
    if (project.inFlight) {
      if (project.startPeriod + project.duration < currentPeriod) {
        project.status = status.completed
      } else {
        project.status = status.in_flight
      }
    } else {
      project.status = status.scheduled
    }
    return
  } else if (project.inFlight === true) {
    project.status = status.suspended
  } else {
    project.status = status.candidate
  }
}

export const makeAlive = (project: Project) => {
  if (project.status === status.candidate) {
    updateStatus(project, status.scheduled)
  } else if (project.status === status.suspended) {
    updateStatus(project, status.in_flight)
  }

}

export const makeDead = (project: Project) => {
  if (project.status == status.scheduled) {
    updateStatus(project, status.candidate)
  }
  if (project.status == status.in_flight) {
    updateStatus(project, status.suspended)
  }
}

/**
 * This function is used to convert project status enum to a string for display purposes
 * @param status 
 */
export const projectStatusToString = (state: status) => {
  switch (state) {
    case status.candidate:
      return "Candidate"
      break

    case status.scheduled:
      return "Scheduled"
      break

    case status.in_flight:
      return "In flight"
      break

    case status.suspended:
      return "Suspended"
      break

    case status.completed:
      return "Completed"
      break

    case status.excluded:
      return "Excluded"
      break

    default:
      return "Not Set"
      break
  }
}