export const getJobs = (token: string): Promise<any> => {
  return makeGetRequest(`projectData`, token)
}

export const getConfig = (token: string, id: string): Promise<any> => {
  return makeGetRequest(`config/${id}`, token)
}

export const getJobAlerts = (token: string): Promise<any> =>{
   return makeGetRequest('jobAlerts',token)
}

export const getCurrentScenarios = (token: string): Promise<any> => {
  return makeGetRequest(`scenarios`, token)
}

export const getScenario = (token: string, id: string): Promise<any> => {
  return makeGetRequest(`scenario/${id}`, token)
}

export const getGlobalData = (token: string): Promise<any> => {
  return makeGetRequest("globalData", token)
}

export const getBaselines = (token: string): Promise<any> => {
  return makeGetRequest("baselines", token)
}

export const getCapabilities = (token: string): Promise<any> => {
  return makeGetRequest("capabilities/current", token)
}

export const getMaturityLevels = (token: string): Promise<any> => {
  return makeGetRequest("maturitylevels/current", token)
}

export const getImage = (token:string, key:string): Promise<any> =>{
  return makeGetRequest(`image/${key}`, token)
}

export const getImages = (token:string) : Promise<any> => {
  return makeGetRequest("images", token)
}

export const getImageUploadURL = (token:string, key:string, type:string ): Promise<any> =>{
  // return makeGetRequest(`imageUploadURL/${key}`, token)
  return new Promise((resolve, reject) =>
  fetch(`${process.env.REACT_APP_API_URL}/imageUploadURL/${key}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    body:JSON.stringify({type, test:"test"})
    
  })
    .then(async result => {
      resolve(result.json())
    })
    .catch(reject)
)
}

export const refreshJob = (token:string, key:string) : Promise<any> => {
  return makeGetRequest(`biarri/refresh/${key}`, token)
}


export const getOptimisations = (token: string, body: any) : Promise<any> =>{
  // return makeGetRequest("jobList", token)
  return new Promise((resolve, reject) =>
  fetch(`${process.env.REACT_APP_API_URL}/jobList`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    body:JSON.stringify(body)
    
  })
    .then(async result => {
      resolve(result.json())
    })
    .catch(reject)
)
}

export const getFailedOptimisations = (token: string) : Promise<any> =>{
  return makeGetRequest("jobFailList", token)
}

const makeGetRequest = (url: string, token: string): Promise<any> => {
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(async result => {
        resolve(result.json())
      })
      .catch(reject)
  )
}

const makeImageGetRequest = (url: string, token: string): Promise<any> => {
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        type:""
      }
    })
      .then(async result => {
        resolve(result.json())
      })
      .catch(reject)
  )
}
