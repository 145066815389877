/**
 * This file is for delting from the database in one central location
 */

/**
 * deletes the given Scenario and its releated projects
 * @param id the scenarios id number
 * @param token authID authorization Token
 */
export const deleteScenario = (id: number, token: string): Promise<any> => {
    let messageBody: any
    messageBody = {
      id,
      delete: true
    }
  
    return new Promise((resolve, reject) =>
      fetch(`${process.env.REACT_APP_API_URL}/scenario`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(messageBody)
      })
        .then(async result => {
          resolve(result.json())
        })
        .catch(reject)
    )
}
  