import { observable, toJS, extendObservable, computed } from "mobx"
import CapabilityStore from "./CapabilitiesStore"
import Project, { Mandatory, Prerequisites, ProjectProps, status } from "./Project"
import { isUndefined } from "util"
import RootStore from "./RootStore"
import { attribute } from "./Attribute"
import { PeriodSelector } from "../components/inputOptions/PeriodSelector"
import ProjectType from "../components/RightSideComponents/ProjectType"

/**
 * possible options for optimisation against
 */
export enum OptimisationParameter {
  benefit = "benefit",
  strategicIndex = "strategicIndex",
  constraint = "constraint"
}

export interface ScenarioProps {
  id: number
  settings: any[]
  constraints: any
  projects: Project[]
  name: string
  description: string
  selectedProjectCode: string | undefined
  highlightedProjectCode: string | undefined
  baselineId: number
  isBaseline: boolean
  currentPeriodIndex: number
}

export class Scenario {
  @observable public settings: any[] = []
  @observable public constraints: any[] = []
  @observable public projects: Project[] = []
  @observable public name: string = "default"
  @observable public description: string = "No Description Given"
  @observable public version: number = 0
  @observable public isBaseline: boolean = false
  @observable public currentPeriodId = 0;

  @observable public optimisationParameter: OptimisationParameter =
    OptimisationParameter.benefit
  @observable public optimisationAttribute: string = ""
  @observable public optimisationMode: "Min" | "Max" = "Max"

  public rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }


  public idToCode = (id: number | undefined) => {
    let code: string | undefined
    if (id !== undefined) {
      const project = this.projects.find((p: Project) => p.id === id)
      if (project) {
        code = project.code
      }
    }
    return code
  }

  /**
   * return the current period object from a given list
   * internally functions identically to currentPeriodIndex
   * @param periods the list of periods objects
   * @returns the period object if found, otherwise undefined
   */
  public currentPeriod = (periods: any[]) =>{
    let index = this.currentPeriodIndex(periods)
    let currentPeriod = undefined
    if( index != -1){
      currentPeriod = periods[index]
    }
    return currentPeriod
  }

  /**
   * Returns the current period index for a given list of periods using period ID
   * @param periods the list of periods to get the index of
   * @returns period index if found, otherwise -1
   */
  public currentPeriodIndex = (periods: any[]) =>{
    // let periodsSimple :any[] = [periods]
    // for(let i =0; i < periods.length ; i++){
    //   periodsSimple[i]= {key:periods[i].index}
    // }

    let returnPeriod = periods.findIndex((period:any ) => period.index == this.currentPeriodId)
    return returnPeriod
  }

  /**
   * Covers a project code to a project index for this scenario
   * @param code the project code
   * @returns project index if found, otherwise -1
   */
  public codeToIndex = (code: string) => {
    return this.projects.findIndex((p: Project) => p.code === code)
  }

  /**
   * increase the version number for this scenario
   * this is used for checking if the scenario has being edited
   */
  public increaseVersion = () => {
    this.version++
  }
}

  

/**
 * sums up the total of an attribute across all periods of a project
 *
 * @param project the project being sumed
 * @param target the attribute which the you want totaled
 * @returns the total of the attribute or 0 if it cant be found
 * 
 * @deprecated use project.attributeTotal ? 
 */
export const projectAttributeTotal = (project: Project, target: string) => {
  // let size = 0
  // project.attributes.forEach(period => {
  //   // if we can find the attribute -(getSizeFrom) add it to the total
  //   // let attr = period.find(attribute => target === attribute.name)
  //   size += attr ? attr.value : 0
  // })
  let size = project.attributeTotal(target)

  return size
}

//data load functions
export const formatProjects = (data:any)=>{
  let projects: Project[] =[]
      //load in all the projects
      projects = data.Projects.map((project: any) => {
        const startPeriods = project.startPeriods
          ? JSON.parse(project.startPeriods)
          : []
        // if you can do this neater please do, 
        // its lower case in csv and upper in enum for display purposes
        if (project.mandatory === "mandatory") {
          project.mandatory = "Mandatory"
        }
  
        // create array to store attributes
        let newAttributes: attribute[] = new Array<attribute>()
        const constraints = JSON.parse(data.constraints)
        constraints.forEach((constraint: any) => {
          newAttributes.push(new attribute(constraint.name))
        });
  
        let newBenefit: attribute = new attribute(project.benefitString)
        let benefitSet = false
        newBenefit.simplified = true // REMOVE THIS when we are using per-period benefits
  
        // fill with data
        project.ProjectAttributes.map((attr: any) => {
          if (attr.name != project.benefitString) { // all non-benefit attributes
            let index = newAttributes.findIndex((a: attribute) => a.name === attr.name)
            // if start and end are 0 value covers the whole range
            if (parseInt(attr.period) === 0 && parseInt(attr.endPeriod) === 0) {
              for (let d = 0; d < project.duration; d++) {
                newAttributes[index].setPeriod(d, attr.value)
              }
              newAttributes[index].simplified = true;
            } else { // otherwise is marked per period
              for (
                let period = parseInt(attr.period);
                period <= parseInt(attr.endPeriod) && period <= project.duration;
                period++
              ) {
                newAttributes[index].setPeriod(period - 1, attr.value)
  
              }
            }
          } else { // benefit
            // if start and end are 0 value covers the whole range
            if (parseInt(attr.period) === 0 && parseInt(attr.endPeriod) === 0) {
              for (let d = 0; d < 99; d++) { // using 99 here, should be done more dynamically in the future
                newBenefit.setPeriod(d, attr.value)
              }
              newBenefit.simplified = true;
            } else { // otherwise is marked per period
              for (
                let period = parseInt(attr.period);
                period <= parseInt(attr.endPeriod);
                period++
              ) {
                newBenefit.setPeriod(period - 1, attr.value)
              }
            }
            benefitSet = true
          }
        })

        if(benefitSet == false){
          //check for old style benefit
          if(project.benefit != null){
            for (let d = 0; d < 99; d++) { // using 99 here, should be done more dynamically in the future
              newBenefit.setPeriod(d, project.benefit)
            }
            newBenefit.simplified = true;
          }
        }
  
        let newStatus = project.projectStatus
        if(newStatus === undefined || newStatus === NaN){
          newStatus = status.candidate
        }
        const newProject: ProjectProps = {
          initiativeId: project.ProjectInitiativeId,
          typeId: project.ProjectTypeId,
          scenarioId: project.ScenarioID,
          strategyId: project.ProjectInitiative.ProjectStrategyId,
          alive: project.alive,
          inFlight: project.inFlight,
          status: newStatus,
          // benefits: [{ start: 0, end: 0, value: project.benefit as number }],
          benefits: newBenefit,
          capabilities: JSON.parse(project.capabilities),
          code: project.code,
          duration: project.duration,
          edited: false,
          id: project.id,
          mandatory: project.mandatory,
          name: project.name,
          // prerequisites: JSON.parse(project.prerequisites),
          prerequisites: [], // filled below
          PhaseId: project.projectPhaseId,
          startPeriod: project.startPeriod !== undefined ? project.startPeriod : 1,
          startPeriods,
          fromDB: true,
          strategicIndex: 0,
          attributes: newAttributes,
        }
  
        const prerequisites = JSON.parse(project.prerequisites)
        prerequisites.map((Prereq: any) => {
          if (typeof Prereq === "string") {
            newProject.prerequisites.push({
              name: Prereq,
              type: Prerequisites.before,
            })
          } else {
            newProject.prerequisites.push(Prereq)
          }
        })
        return new Project(newProject)
      })

  return projects
}

//end data load functions




export default Scenario
