import { observable } from 'mobx';
import RootStore from './RootStore';
import * as localForage from 'localforage'
 
const CANOPTIMISE =['Admin','Analysis' ]
const CANIMPORT = ['Admin']

export interface User {
    identity: Identity
    profile: Profile
  }
  
  export interface Identity {
    accessToken: string
    expiresIn: number
    idToken: string
    state: string
    tokenType: 'Bearer' | ''
  }
  
  export interface Profile {
    picture: string
    nickname: string
    name: string
    permissions: string[]
    roles: string[]
  }


class PermissionStore {
  public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
  }

  @observable public showDataImportView: boolean = false
  @observable public role: string = ""
  @observable public user: User | null = null
  @observable public loggedIn: boolean = false

  public async getUserFromLocalForage() {
    const user = await localForage.getItem('user')
    return user
  }

  public setPermissions(permissionsArray: any[]): void {
    const permissionTree = permissionsArray.reduce((permTree, permission) => {
      const [resource, perm] = permission.toLowerCase().split(':')
      permTree[resource + '_' + perm] = true
      return permTree
    }, {})

    this.showDataImportView = permissionTree.dataimport_update
  }

  public canOptimise():Boolean{
    if(this.user !== null){
      //check rolls
      let roles = this.user.profile.roles;
      return (roles.some(role => CANOPTIMISE.includes(role)))
    }
    return false
  }

  public setUser = (user: User): void => {
    this.user = user;
    //check for dataImport
    try {
      this.showDataImportView = (user.profile.roles.some(role => CANIMPORT.includes(role)))
    } catch (error) {
      console.log("Error in authentication, some features may not be available to you")
    }
  }
  public setLoggedIn = (loggedIn: boolean): void => {
    this.loggedIn = loggedIn;
  }

  public getToken = (): string => {
    return this.user && this.user.identity.idToken || ''
  }
}

export default PermissionStore;