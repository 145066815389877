import * as React from "react"
import styles from "./styles"
import getMaturityStyle from "../../utils/capabilityMap/getMaturityStyle"
import { Capability, MaturityLevel } from "../../mobx/BaselineStore"
import { isUndefined } from "util"

import mapMaturityToColor from "../../utils/capabilityMap/mapMaturityToColor"
import mapMultiplierToColor from "../../utils/capabilityMap/mapMultiplierToColor"

import UiStore from "../../mobx/UiStore"
import CapabilityStore from "../../mobx/CapabilitiesStore"
import { inject } from "mobx-react"
import { toJS } from "mobx"
import * as R from "ramda"

export interface Props {
  mapValueToColor: any
  capability: Capability
  mode: any
  maturityMap: MaturityLevel[]
  calculateMaturityPortolioStyle: any
  displayValue: boolean
  clickedOn: any
}

interface InjectedProps extends Props {
  capabilityStore: CapabilityStore
  uiStore: UiStore
}
@inject(({ store }) => {
  return {
    capabilityStore: store.capabilityStore,
    uiStore: store.uiStore
  }
})
class Level2 extends React.Component<Props, any> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  public clickedOn = (e: any) => {
    e.stopPropagation()
    this.props.clickedOn(this.props.capability.code)
  }

  private selected = (style: any) => {
    if (
      this.props.capability.code === this.injected.uiStore.capabilityMapClicked
    ) {
      return (style = R.merge(style, {
        backgroundColor: "#546E7A",
        color: "#000"
      }))
    }
  }

  public render() {
    const {
      mapValueToColor,
      capability,
      mode,
      maturityMap,
      calculateMaturityPortolioStyle,
      displayValue
    } = this.props

    // const level2Style = mapValueToColor(capability.code, styles.capLevel2)
    let level2Style:any = styles.capLevel2

    switch (mode) {
      case "heatmap":
        level2Style = mapValueToColor(capability.code, styles.capLevel2)
        const value =
          displayValue === true && !isUndefined(level2Style.expenditure)
            ? "\n : " + level2Style.expenditure
            : ""
        this.selected(level2Style)
        return (
          <div key={capability.id} style={level2Style} onClick={this.clickedOn}>
            {" "}
            {capability.name}
            {value}
          </div>
        )
        break

      case "maturityTarget":
        if (capability.MaturityCurrent && capability.MaturityTarget) {
          const currentStyle = mapMaturityToColor(
            capability.MaturityCurrent,
            maturityMap,
            true
          )
          const targetStyle = mapMaturityToColor(
            capability.MaturityTarget,
            maturityMap,
            false
          )
          return (
            <div
              key={capability.id}
              style={level2Style}
              onClick={this.clickedOn}
            >
              <div
                style={{
                  color: "black",
                  textAlign: "center"
                }}
              >
                {" "}
                {capability.name}
              </div>
              <div style={styles.capabilityNameOver}>{capability.name}</div>
              <div style={currentStyle} />
              <div style={targetStyle} />
            </div>
          )
        }

        // level2Style = this.selected(level2Style)
        return (
          <div key={capability.id} style={level2Style} onClick={this.clickedOn}>
            <div
              style={{
                color: "black",
                textAlign: "center"
              }}
            >
              {" "}
              {capability.name}
            </div>
          </div>
        )
        break

      case "maturityPortfolio":
        if (capability.MaturityTarget) {
          const portfolioStyle = calculateMaturityPortolioStyle(
            capability,
            maturityMap
          )
          const targetStyle = mapMaturityToColor(
            capability.MaturityTarget,
            maturityMap,
            false
          )
          const value =
            displayValue === true && !isUndefined(portfolioStyle.maturity)
              ? "\n :" + portfolioStyle.maturity
              : ""

          return (
            <div
              key={capability.id}
              style={level2Style}
              onClick={this.clickedOn}
            >
              <div
                style={{
                  color: "black",
                  textAlign: "center"
                }}
              >
                {" "}
                {capability.name}
              </div>
              <div style={styles.capabilityNameOver}>
                {capability.name}
                {value}
              </div>
              <div style={portfolioStyle} />
              <div style={targetStyle} />
            </div>
          )
        }

        this.selected(level2Style)
        return (
          <div key={capability.id} style={level2Style} onClick={this.clickedOn}>
            <div
              style={{
                color: "black",
                textAlign: "center"
              }}
            >
              {" "}
              {capability.name}
            </div>
          </div>
        )
        break

      case "importanceLevel":
        
        level2Style = mapValueToColor(capability.code, styles.capLevel2)
        let capabilityStore = toJS(this.injected.capabilityStore)
        const multipValue =
          displayValue === true
            ? capabilityStore.getMultiplierLevel(capability.id)
            : ""
        let newStyle = level2Style
        let colour = mapMultiplierToColor(capability.code, capabilityStore)
        newStyle.backgroundColor = colour

        newStyle = this.selected(newStyle)
        return (
          <div key={capability.id} style={level2Style} onClick={this.clickedOn}>
            {" "}
            {capability.name}
            {multipValue}
          </div>
        )
        break

      default:
        return null
        break
    }
  }
}
export default Level2
