import * as R from "ramda"
import { MaturityLevel } from "../../mobx/BaselineStore"

const mapMaturityToColor = (
  level: any,
  maturityMap: MaturityLevel[],
  isLeft: any
): any => {
  let boxStyle = {
    position: "absolute",
    width: "50%",
    top: 0,
    height: "100%"
  }
  if (isLeft) {
    boxStyle = R.merge(boxStyle, {
      left: 0,
      borderRight: "0.5px dotted #999"
    })
  } else {
    boxStyle = R.merge(boxStyle, { right: 0 })
  }

  // const index = maturityMap.findIndex((i: any) => i.level === level.toString())
  let matureColor = "white"
  // if (index !== -1) {
    if(level){
      matureColor = level.color
    }
  // }

  boxStyle = R.merge(boxStyle, { backgroundColor: matureColor })

  return boxStyle
}

const mapMaturityToColorSimple = (level: any, maturityMap: any): string => {
  const index = maturityMap.findIndex((i: any) => i.level === level.toString())
  const matureColor = maturityMap[index].color
  return matureColor
}
export default mapMaturityToColor
