import Project, { ProjectProps, Mandatory, status } from "../mobx/Project"
import { project } from "ramda"
import { upperFirst } from "lodash"

/**
 * Calculate the total usage of a resource /attribute within a absoulte timeslot
 * will not count non-alive projects
 * slot === period -- rename this someday? 
 * @param projects the projects to get the resource from
 * @param timePeriod absolute period to calculate for
 * @param resource chosen attribute name
 */
export function usageInTimeslot(
  projects: Project[],
  timePeriod: number,
  resource: string
) {
  let total: number = 0

  projects.forEach(project => {
    if (projectInTimeSlot(project, timePeriod) && project.alive) {
      //get relative period
      const startPeriod = project.startPeriod != undefined? project.startPeriod : 0
      let relativePeriod = timePeriod - startPeriod

      const value = project.getAttribute(resource, relativePeriod)
      let addition = value !== undefined ? value : 0
      total += addition

      if(value === undefined){
        //we didn't get the value, try to use first periods value
        const value2 = project.getAttribute(resource, 0)
        if(value2 != undefined){
          total += value2
        }else{
        console.error("undefined value in Usage - line: 22 mobxUtils");
        console.log(value)
        }
      }
    }
  })
  // for (const project of projects) {
  //   if (projectInTimeSlot(project, timePeriod) && project.alive) {
  //     const value = project.getAttribute(resource, timePeriod)
  //     total += value !== undefined ? value : 0
  //   }
  // }
  return total
}

/**
 * returns the usage in a timeslot for a single project
 *
 * @param project project the resource is coming from
 * @param timeslot absoulte timeslot to calulate for
 * @param resource chosen attribute name
 */
export function usageInTimeslotSingle(
  project: Project,
  timeslot: number,
  resource: string
) {
  return usageInTimeslot([project], timeslot, resource)
}

/**
 * checks to see if a project is alive in a given timeslot
 * @param project the project to check
 * @param timeslot the time slot to check for
 */
export function projectInTimeSlot(project: Project, timeslot: number): boolean {
  if (
    project.startPeriod <= timeslot &&
    project.startPeriod + project.duration > timeslot
  ) {
    return true
  }
  return false
}

export function updateAttributeLength(values: number[], length: number, fill = 0):number[] {
  let difference = length - values.length
  let newValues = values
  if (difference > 0) {
      // grow
      for (let i = 0; i < difference; i++) {
          newValues.push(fill)
      }
  } else if (difference < 0) {
      //shrink
      newValues= newValues.slice(0, length)
  }      
return newValues
}

//
// This isn't used, and the maths behind it doesnt make sense so commented out till have a use so can redo
//
// /**
//  * calulate the minium constrants needed to meet Mandatory projects
//  */
// export function calulateMandatory(
//   Projects: ProjectProps[],
//   constraints: any[]
// ): Array<{ name: string; value: number }> {
//   const mandatoryConstraints: Array<{ name: string; value: number }> = []
//   constraints.forEach(constraint => {
//     mandatoryConstraints.push({ name: constraint.name, value: 0 })
//   })
//   Projects.forEach(project => {
//     if (project.mandatory === Mandatory.Mandatory) {
//       project.attributes.forEach(attr => {
//         mandatoryConstraints.forEach(cont => {
//           if (attr.name === cont.name) {
//             cont.value += attr.value
//           }
//         })
//       })
//     }
//   })
//   return mandatoryConstraints
// }
export function timePeriodChecks(newPeriod: number, projects: Project[]): { code: string; status: status}[] 
 {
  let Changed : {code: string, status: status}[] = []
  // console.log("new Period = " + newPeriod)
  projects.forEach((project, index)=> {
    // console.log(project.name)
    // console.log("start Period: " + project.startPeriod + " , status: " + project.status)
    // console.log("excluded: " + project.mandatory)
    //check if due to start (has start period and is scheduled), not excluded and not completed
    if(newPeriod >= project.startPeriod &&
       project.status !== status.candidate && 
       project.mandatory !== Mandatory.Excluded &&
       project.status !== status.completed ){
        //check if should be completed
        if(newPeriod > (project.startPeriod + project.duration)){
          Changed.push({code:project.code,status:status.completed})
          console.log("completed")
        }else if (project.status !== status.in_flight){
          Changed.push({code:project.code, status:status.in_flight})
          console.log("in_flight")
        }
    }
    //check for in-flight/ completed becoming scheduled
    if(newPeriod < project.startPeriod && (project.status === status.in_flight ||project.status === status.completed)) {
      Changed.push({code:project.code, status:status.scheduled})
      console.log("scheduled")

    }//check for completed becoming in-flight again
    else if(newPeriod <= (project.startPeriod + project.duration) && project.status === status.completed){
      Changed.push({code:project.code,status:status.in_flight })
      console.log("in_flight")
    }
  });
  return Changed
}

  /**
   * Returns the current period index for a given list of periods using period ID
   * @param periods the list of periods to get the index of
   * @returns period index if found, otherwise -1
   */
   export function findCurrentPeriodIndex(periods: any[], currentPeriodId:number ):number
   {
    // let periodsSimple :any[] = [periods]
    // for(let i =0; i < periods.length ; i++){
    //   periodsSimple[i]= {key:periods[i].index}
    // }

    let currentPeriod = periods.findIndex((period:any ) => period.index == currentPeriodId)
    return currentPeriod
  }
