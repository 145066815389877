import * as React from "react";
import { observer, inject } from "mobx-react";

import PermissionStore from "../../mobx/PermissionStore";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import DatePicker from "material-ui/DatePicker";

import {
  getOptimisations,
  getFailedOptimisations,
  refreshJob,
} from "../../utils/getData";
import NavigationRefresh from "material-ui/svg-icons/navigation/refresh";
import Toggle from "material-ui/Toggle";
import UiStore from "../../mobx/UiStore";
import { getSolutions } from "../../utils/ProjectSolution";
import IconButton from "material-ui/IconButton";
import { isUndefined } from "lodash";
import TextField from "material-ui/TextField";

const defaultResultNumber = 50
interface InjectedProps extends Props {
  permissionStore: PermissionStore;
  uiStore: UiStore;
}

export interface Props {}
export interface State {
  optimisations: any[];
  startDate: Date;
  endDate: Date;
  resultNumber: number;
  resultError: boolean;
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
  };
})
@observer
export class OptimisationStatus extends React.Component<Props, State> {

  private get injected() {
    return this.props as InjectedProps;
  }
  resultNumber = defaultResultNumber

  constructor(props: Props) {
    super(props);
    this.state = {
      optimisations: [],
      endDate: new Date(),
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      resultNumber: defaultResultNumber ,
      resultError: false,
    };
  }

  public async componentDidMount() {
    await this.loadOptimisations();
  }

  private toggleShowSolved = (event: any, checked: boolean) => {
    this.injected.uiStore.showSolvedOptimisations = checked;
    this.loadOptimisations();
  };

  private handleChangeStartDate = (event: any, date: Date) => {
    this.setState({ startDate: date });
    this.loadOptimisations();
  };

  private handleChangeEndDate = (event: any, date: Date) => {
    this.setState({ endDate: date });
    this.loadOptimisations();
  };

  private handleChangeResultNumber = (event:any ) =>{
    let newValue = parseInt(event.target.value, 10)
    this.resultNumber = newValue
    let resultError = false
    if(newValue === undefined ||  isNaN(newValue) || newValue === null || newValue < 1){
      newValue = defaultResultNumber
      this.resultNumber = defaultResultNumber
      resultError = true
    }
    this.setState({resultNumber: newValue, resultError: resultError})
    this.loadOptimisations();
  }

  private loadOptimisations = async () => {
    const { permissionStore } = this.injected;
    const showSolvedOptimisations =
      this.injected.uiStore.showSolvedOptimisations;

    const data = await getOptimisations(permissionStore.getToken(), {
      failedOnly: !showSolvedOptimisations,
      numberToReturn: this.resultNumber,
      useDates: true,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
    console.log(data);
    this.setState({ optimisations: data.jobs });
  };

  private refreshSingle = async (id: string) => {
    const { permissionStore } = this.injected;
    console.log(id);
    const solution = await refreshJob(id, permissionStore.getToken());
  };

  public render(): JSX.Element {
    const optimisations = this.state.optimisations;
    const styles = {
      block: {
        maxWidth: 250,
        padding: "30px 30px 30px 0px",
      },
      flexContainer:{
        display: "flex",
        justifyContent: 'center',
        maxHeight:200
      },
      toggle: {
        marginBottom: 16,
      },
    };

    //query options -> sent to backend to help sculpt db query
    let errorMessage = ""
    if (this.state.resultError === true){
      errorMessage = "Please enter a number greater then 0"
    }
    const selectors = (
      <div>
        <div style={styles.flexContainer}>
        <div style={styles.block}>
          <Toggle
            label="Show solved Optimisations"
            toggled={this.injected.uiStore.showSolvedOptimisations}
            onToggle={this.toggleShowSolved}
            style={styles.toggle}
          />
          </div>
          <TextField 
          floatingLabelText={"Number of Results to Return"}
           name="results" 
           value ={this.state.resultNumber}
           onChange = {this.handleChangeResultNumber}
           errorText= {errorMessage}>

          </TextField>
        </div>

        <div style={styles.flexContainer}>
          <div>
            Start Date
            <DatePicker
              hintText="Start Date"
              container="inline"
              autoOk={true}
              onChange={this.handleChangeStartDate}
              value={this.state.startDate}
            ></DatePicker>
          </div>
          <div>
            End Date
            <DatePicker
              hintText="End Date"
              container="inline"
              autoOk={true}
              onChange={this.handleChangeEndDate}
              value={this.state.endDate}
            ></DatePicker>
          </div>
        </div>
      </div>
    );

    // return case for undefined optimization
    let dataDisplay = (
      <h3>
        An error occurred while retrieving data, please refresh this page or
        contact your service provider
      </h3>
    );

    if (!isUndefined(optimisations)) {
      dataDisplay = (
        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableHeaderColumn>Id</TableHeaderColumn>
            <TableHeaderColumn>created At</TableHeaderColumn>
            <TableHeaderColumn>job Id</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Replaced on</TableHeaderColumn>
            <TableHeaderColumn>Refresh</TableHeaderColumn>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {optimisations.map((optimisation, o) => {
              let created = new Date(optimisation.createdAt as string);
              const refresh = () => {
                this.refreshSingle(optimisation.jobId);
              };

              return (
                <TableRow key={optimisation.id}>
                  <TableRowColumn>{optimisation.id}</TableRowColumn>
                  <TableRowColumn>{created.toLocaleString()}</TableRowColumn>
                  <TableRowColumn>{optimisation.jobId}</TableRowColumn>
                  <TableRowColumn>{optimisation.status}</TableRowColumn>
                  <TableRowColumn>{optimisation.deletedAt}</TableRowColumn>
                  <TableRowColumn>
                    {
                      <IconButton onClick={refresh} disabled={true}>
                        <NavigationRefresh />
                      </IconButton>
                    }
                  </TableRowColumn>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }

    return (
      <div>
        <h3> Current Optimizations </h3>
        {selectors}
        {dataDisplay}
      </div>
    );
  }
}
