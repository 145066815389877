import * as React from "react"
import * as R from "ramda"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"

import Paper from "material-ui/Paper"
import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"

import { ResourceDialList } from "./ResourceDialList"
import { ResourceSplitDialList } from "./ResourceSplitDialList"
import { ResourceBarList } from "./ResourceBarList"

import UiStore from "../../mobx/UiStore"
import ScenariosStore from "../../mobx/ScenariosStore"
import BaselineStore from "../../mobx/BaselineStore"
import { Project } from "../../mobx/Project"
import { isNullOrUndefined } from "util"
import { findCurrentPeriodIndex } from "../../utils/mobxUtils"

export interface Props {
  selectedProject: string | undefined
  setSelectedProject: (id: string | undefined) => void
  clearSelection(): void
}

interface InjectedProps extends Props {
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
}

export interface State {
  selectedConstraints: any
  numberOfConstraints: number
}

@inject(({ store }) => {
  return {
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
  }
})
@observer
class RightSideBar extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedConstraints: [],
      numberOfConstraints: 3,
    }
  }

  private onPressConstraint = (constraint: any) => {
    const { uiStore } = this.injected
    uiStore.showScenarioSettingsDialog = true
    uiStore.selectedConstraint = constraint.id
  }

  private getSelectedDials = (constraints: any[]) => {
    let returnArray = new Array()
    returnArray = constraints.filter((con: any) => con.selected === true)

    // make sure display and drop down match
    if (returnArray.length !== this.state.selectedConstraints.length) {
      const newConst = new Array()
      returnArray.forEach((con) => {
        newConst.push(con.id)
      })
      this.setState({ selectedConstraints: newConst })
    }

    // check for empty
    if (returnArray.length <= 0) {
      returnArray = R.take(this.state.numberOfConstraints)(constraints)
    }

    return returnArray
  }

  private selectionRenderer = (values: { length: any }) => {
    switch (values.length) {
      case 0:
        return ""
      case 1:
        return this.getSelectedDials(
          this.injected.scenariosStore.working.constraints
        )[0].name
      default:
        return `${values.length} Constraints selected`
    }
  }

  private constraintChange = (event: any, index: any, values: any) => {
    const { scenariosStore } = this.injected
    const newConst = (values as [number]).slice(
      0,
      this.state.numberOfConstraints
    )

    scenariosStore.working.constraints.forEach((constraint) => {
      // update selected on all constraints
      constraint.selected = !isNullOrUndefined(
        newConst.find((c: any) => c === constraint.id)
      )
    })
    scenariosStore.optimised.constraints.forEach((constraint) => {
      constraint.selected = !isNullOrUndefined(
        newConst.find((c: any) => c === constraint.id)
      )
    })
    this.setState({ selectedConstraints: newConst })
  }

  public render(): JSX.Element {
    const { scenariosStore, uiStore, baselineStore } = this.injected
    const { showHoverbox, hideHoverbox, changeHoverboxPosition, showAllPeriods } = uiStore
    const constraints = toJS(scenariosStore.working.constraints)
    const dialChoice = this.getSelectedDials(constraints)
    const projectName =
      (scenariosStore.displayProject && scenariosStore.displayProject.name) ||
      ""
    const periodArray = baselineStore.periods
    const projectArray = scenariosStore.working.projects
    const currentPeriod = findCurrentPeriodIndex(periodArray, scenariosStore.working.currentPeriodId)
    // const currentPeriod = scenariosStore.working.currentPeriodIndex(periodArray) // not sure if this should be optimised or working
    let startPeriod = 0
    if(showAllPeriods ==  false){
      startPeriod = currentPeriod
    }

    const returnItems = (
      <Paper zDepth={2} style={{ width: "460px" }}>
        <div style={styles.wrapper}>
          <div style={styles.resourceDialListContainer}>
            <div style={{ paddingLeft: "10px", fontSize: "110%" }}>
              Resource constraints
            </div>
            <SelectField
              style={{ paddingLeft: "10px", fontSize: "110%" }}
              onChange={this.constraintChange}
              hintText={"No Constraint Selected"}
              value={this.state.selectedConstraints}
              multiple={true}
              selectionRenderer={this.selectionRenderer}
            >
              {constraints.map((con) => {
                return (
                  <MenuItem
                    key={con.id}
                    value={con.id}
                    primaryText={con.name}
                  />
                )
              })}
            </SelectField>
            <ResourceDialList
              projectArray={projectArray}
              dialChoice={dialChoice}
              onPressConstraint={this.onPressConstraint}
              showHoverbox={showHoverbox}
              hideHoverbox={hideHoverbox}
              changeHoverboxPosition={changeHoverboxPosition}
              selectedProject={this.props.selectedProject}
              periodArray={periodArray}
              currentPeriod={currentPeriod}
              startPeriod={startPeriod}
            />
          </div>

          <ResourceBarList
            dialChoice={dialChoice}
            projectArray={scenariosStore.working.projects}
            showHoverbox={showHoverbox}
            hideHoverbox={hideHoverbox}
            changeHoverboxPosition={changeHoverboxPosition}
            selectedProject={this.props.selectedProject}
          />
          <div style={styles.projectNameContainer}>
            <div>{projectName}</div>
          </div>
          <div style={styles.splitDialListContainer}>
            <ResourceSplitDialList
              projectArray={scenariosStore.working.projects}
              dialChoice={dialChoice}
              showHoverbox={showHoverbox}
              hideHoverbox={hideHoverbox}
              changeHoverboxPosition={changeHoverboxPosition}
              selectedProject={this.props.selectedProject}
              setSelectedProject={this.props.setSelectedProject}
              clearSelection={this.props.clearSelection}
            />
          </div>
        </div>
      </Paper>
    )

    return returnItems
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    flex: "0 1 100%",
    height: "95%",
    display: "flex",
    flexDirection: "column",
  },
  resourceDialListContainer: {
    backgroundColor: "none",
    padding: "10px",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  projectNameContainer: {
    paddingBottom: "10px",
    width: "90%",
    minHeight: "80px",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "150%",
  },
  splitDialListContainer: {
    marginTop: "auto",
  },
}

export default RightSideBar
